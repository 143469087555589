import React from 'react'

import Overlay from '../../Overlay/Overlay'
import styles from './Consent.module.scss'

export default function Consent({
  isOpen,
  setIsOpen,
}: {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
}) {
  return (
    <Overlay isOpen={isOpen} background="white" setIsOpen={setIsOpen} title="Vilkår for deltagelse i ’Klassen skriver’">
      <div className={styles.content}>
        <h3>Vilkår for deltagelse i ’Klassen skriver’</h3>

        <h4>Om ’Klassen skriver’</h4>
        <p>Kampagnens formål er at styrke danske børn og unges sproglige selvtillid og give dem medbestemmelse ved at bringe deres egne liv, erfaringer og interesser i spil.</p>
        <p>Børn og unge under 15 år kan derfor indsende deres egne idéer til et afsnit af ’Klassen’ og stemme på andres idéer.</p>
        <p>De 10 idéer, der vinder, får sammen med deres klasse besøg af en skuespiller og en manuskriptforfatter fra ’Klassen’, som sammen med klassen vil skrive et afsnit til en kommende sæson af ’Klassen’.</p>
        <p>Kampagnen er en del af projektet ’Alle ord tæller’, som har til formål at skabe bedre forudsætninger for gode læseoplevelser og derigennem styrke læselysten og læsekulturen for danske børn og unge i målgruppen 1-14 år. Projektet er støttet af TrygFonden.</p>

        <h4>Indsendelse af idéer</h4>
        <ul>
          <li>Idéer kan indsendes i perioden fra den 29. oktober 2024 til den 6. december 2024. </li>
          <li>Indsendelse af idéer sker via formularen, der findes på dr.dk/klassenskriver.</li>
          <li>Deltagerne skal være under 15 år. Personer, der er over 15 år, kan ikke deltage.</li>
          <li>Idéen skal overholde en række produktionelle hensyn opstillet af DR: Idéen skal kunne foregå på en skole, udspille sig over 1-2 dage og kunne spilles af ’Klassen’-skuespillere.</li>
          <li>Idéerne må IKKE indeholde hadbeskeder, oplysninger om identificerbare personer, mobning, indehold der i øvrigt er i strid med lovgivningen eller lignende. </li>
          <li>Der skal være en person på minimum 18 år, der hjælper deltageren med at indsende idéen. Den person, der hjælper med at indsende idéen, indestår samtidig for, at forældremyndighedsindehaverne til det barn/de børn, der har fundet på idéen, har givet tilladelse til at sende idéen i henhold til nærværende vilkår, herunder vilkår om rettigheder. Ved delt/fælles forældremyndighed skal begge forældremyndighedsindehavere samtykke. Hvis én forælder/værge har fuld forældremyndighed, skal kun denne give sin tilladelse. Den person over 18 år, der hjælper med at indsende idéen, indestår desuden for, at den forælder eller lærer, hvis kontaktoplysninger deles, har givet samtykke hertil.</li>
          <li>Der kan indsendes flere idéer per deltager, men hver idé skal indsendes enkeltvis.</li>
          <li>Mindst en gang dagligt på arbejdsdage bliver indsendte idéer offentliggjort på hjemmesiden. Indsendte idéer bliver inden offentliggørelsen godkendt af en moderator, som holder øje med, at forslaget ikke er i strid med vilkårene.</li>
          <li>Når en idé bliver offentliggjort, fremgår kun fornavn og by på den, der har indsendt idéen, samt selve idéen. Det vil også fremgå, hvor mange stemmer, idéen løbende har fået. Personlige oplysninger som efternavn, telefonnummer og øvrige kontaktoplysninger til forældre eller lærer vil ikke være synligt.</li>
          <li>DR forbeholder sig retten til ikke at godkende idéer, der ikke er udfyldt korrekt, ikke lever op til de krav, som DR har opstillet for idéerne, eller som er i strid med disse vilkår.</li>
        </ul>

        <h4>Afstemning</h4>
        <p>Alle under 15 år kan stemme på alle idéer én gang. Man kan ikke stemme flere gange på samme idé. Man skal være logget ind på hjemmesiden for at stemme og oprette idéer.</p>

        <h4>Udvælgelse og kontakt af vindere</h4>
        <p>Ud af de 50 idéer, der har fået flest stemmer, udvælger DR i december 2024 10 vindere, der sammen med deres klasse i løbet af januar og februar måned 2025 får besøg af en skuespiller og en manuskriptforfatter fra ’Klassen’, som sammen med klassen vil skrive et afsnit til en kommende sæson af ’Klassen’.</p>
        <p>DR udvælger de 10 vinder-idéer ud fra kriterier som originalitet, kreativitet, relevans for målgruppen og ovennævnte produktionelle hensyn.</p>
        <p>De 10 vindere, som udvælges af DR, kontaktes direkte af DR via kontaktoplysningerne på den forælder eller klasselærer, der er angivet i tilmeldingsblanketten. Dette vil ske i løbet af december 2024. Forælderen eller klasselæreren har herefter 48 timer til at besvare DR’s henvendelse. Hvis tidsfristen ikke overholdes, kan DR vælge at gå videre med en anden vinder i stedet.</p>
        <p>Klasselæreren til vinderen vil herefter blive bedt om at indhente skriftlige samtykker fra forældrene til de børn, der skal være med til at forfatte et afsnit. DR sender en samtykkeerklæring, som skal bruges til dette formål.</p>
        <p>Deltagere, der ikke går videre, vil ikke blive kontaktet.</p>

        <h4>Lancering</h4>
        <p>Den nye sæson af ’Klassen’ er planlagt til at blive lanceret i efteråret 2025 (ændringer kan forekomme), hvor de 10 afsnit vil blive publiceret på DRTV.</p>

        <h4>Rettigheder</h4>
        <p>Ved deltagelse i konkurrencen gives der tilladelse til, at DR får en tidsubegrænset og vederlagsfri eksklusiv ret til alene at anvende og bearbejde idéer på alle måder som led i udarbejdelsen af ’Klassen’-serien. DR’s ret omfatter blandt andet:</p>
        <ul>
          <li>at DR kan publicere idéerne på dr.dk/klassenskriver med henblik på, at man kan stemme på de indkomne forslag,</li>
          <li>at idéerne kan blive publiceret på DR’s platforme samt på DR’s profiler på tredjemandsplatforme (f.eks. Facebook), samt</li>
          <li>at DR kan bruge, udsende, og genudsende det afsnit af ’Klassen’, som idéerne indgår i, og at hele afsnittet eller klip herfra kan blive lagt på DR’s platforme og på tredjemandsplatforme (f.eks. Facebook), ligesom at afsnittet kan blive solgt og overdraget i hele verden til f.eks. tv-visning.</li>
        </ul>

        <h4>Ansvar</h4>
        <p>DR tilstræber, at information og indhold vedrørende konkurrencen til enhver tid er opdateret og korrekt men kan dog ikke garantere, at indholdet er fejlfrit og komplet.</p>
        <p>Alle tvivlsspørgsmål, der må opstå i forbindelse med fortolkningen af disse konkurrencebetingelser, afgøres af DR.</p>

        <h4>Kontakt</h4>
        <p>
          Har du spørgsmål eller brug for hjælp til ’Klassen skriver’, kan du skrive til
          {' '}
          <a href="mailto:alleordtaeller@dr.dk" target="_blank">alleordtaeller@dr.dk</a>
          .
        </p>

        <h4>Databeskyttelse</h4>
        <p>DR's indsamling af personoplysninger sker i overensstemmelse med den gældende lovgivning, herunder databeskyttelsesforordningen (GDPR).</p>
        <p>Ved tilmelding gives der samtykke til, at det indsendte indhold (hvis det indeholder personoplysninger) og de afgivne personoplysninger (kontaktinformationer m.v.) registreres og opbevares af DR og DR's databehandler Signifly ApS for at opfylde ovennævnte formål. Databehandleren registrerer og behandler udelukkende personoplysninger på DR's vegne for at indholdet kan anvendes af DR, og databehandleren kan således ikke selvstændigt råde over disse.</p>
        <p>DR's behandling af oplysningerne sker udelukkende til journalistiske formål og er dermed ikke fuldt ud omfattet af reglerne i GDPR. Du har f.eks. ikke ret til at få oplysningerne slettet efter reglerne i GDPR om ”retten til at blive glemt” m.v.</p>

        <p>
          Du kan læse mere om DR's privatlivspolitik
          {' '}
          <a href="https://www.dr.dk/om-dr/dr-og-dine-data/drs-privatlivspolitik" target="_blank">her</a>
          .
        </p>
      </div>
    </Overlay>
  )
}
