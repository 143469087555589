import Cookies from 'js-cookie'
import { convertKeysToSnakeCase } from '../helpers/convertKeysToSnakeCase'
import type { ProposalColor } from '../types/proposal.type'
import type { ParticipantAuthResponse } from './auth'

interface Payload {
  color: ProposalColor
  icon: string
  title: string
  mainCharacter: string
  conflict: string
  solution: string
  author: string
  grade: string
  school: string
  city: string
  fullname: string
  mobile: string
  parentFullname: string
  parentPhone: string
  permission: boolean
  pin: string
}

export async function createProposal(payload: Payload): Promise<boolean> {
  try {
    const response = await fetch('/api/proposal/create', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
      body: JSON.stringify(convertKeysToSnakeCase(payload)),
    })

    if (response.status === 200) {
      const body = await response.json() as ParticipantAuthResponse
      Cookies.set('token', body.data.token)
    }

    return response.status === 200
  }
  catch (error) {
    throw new Error('Error signing up')
  }
}
