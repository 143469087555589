import React, { useState } from 'react'
import Button from '../Button/Button'
import Input from '../Form/Input/Input'
import { type ProposalColor, darkColors } from '../../types/proposal.type'
import styles from './NewProposal.module.scss'

// const [mainCharacter, setMainCharacter] = useState('')
// const [conflict, setConflict] = useState('')
// const [solution, setSolution] = useState('')
// const [author, setAuthor] = useState('')

export default function Step2({
  setStep,
  color,
  mainCharacter,
  setMainCharacter,
  conflict,
  setConflict,
  solution,
  setSolution,
  author,
  setAuthor,
  city,
  setCity,
}: {
  setStep: (step: number) => void
  color: ProposalColor

  mainCharacter: string
  setMainCharacter: (mainCharacter: string) => void
  conflict: string
  setConflict: (conflict: string) => void
  solution: string
  setSolution: (solution: string) => void
  author: string
  setAuthor: (author: string) => void
  city: string
  setCity: (city: string) => void

}) {
  const [errors, setErrors] = useState<string[]>([])

  const handleValidation = () => {
    let newErrors: string[] = []

    const fields: { [key: string]: string } = {
      mainCharacter,
      conflict,
      solution,
      author,
      city,
    }

    const validationRules: { [key: string]: (value: string) => boolean } = {
      conflict: value => value.length > 150 || value.length === 0,
      author: value => value.length === 0,
      city: value => value.length === 0,
    }

    Object.keys(validationRules).forEach((field) => {
      const isInvalid = validationRules[field as keyof typeof validationRules](fields[field as keyof typeof fields])
      if (isInvalid && !newErrors.includes(field))
        newErrors.push(field)
      else if (!isInvalid)
        newErrors = newErrors.filter(error => error !== field)
    })
    setErrors(newErrors)
    if (newErrors.length === 0)
      setStep(3)
  }

  return (
    <>
      <div className={styles.form}>
        <Input
          label="Hvem er med i afsnittet?"
          name="mainCharacter"
          type="text"
          value={mainCharacter}
          placeholder="F.eks. ”To drenge fra klassen, der er gode venner.”"
          helperText="Hvem er hovedrolle(r) i din idé? Det er som regel 1-2 personer.   "
          setValue={setMainCharacter}
          maxLength={50}
          showCounter={true}
        />
        <Input
          label="Hvad er konflikten?"
          helperText="Hvad synes hovedrolle(rne) er svært?"
          name="conflict"
          type="text-area"
          value={conflict}
          placeholder="F.eks. ”Den ene dreng bliver ked af det, fordi hans ven pranker ham på en måde, han ikke synes er sjov.”"
          setValue={setConflict}
          error={(conflict.length > 150 || errors.includes('conflict')) && 'Må maks være 150 tegn'}
          setError={() => setErrors(errors.filter(error => error !== 'conflict'))}
          white={darkColors.includes(color)}
          maxLength={150}
          showCounter={true}
          required={true}
        />
        <Input
          label="Hvad er løsningen?"
          helperText="Hvordan kommer hovedrolle(rne) ud af sit problem?"
          name="solution"
          type="text-area"
          value={solution}
          placeholder="F.eks. ”De taler om det, og vennen siger undskyld for at lave pranken.”"
          setValue={setSolution}
          maxLength={150}
          showCounter={true}
        />

        <Input
          label="Hvem har skrevet idéen?"
          helperText="Skriv kun dit/jeres fornavn(e)."
          name="author"
          type="text"
          value={author}
          placeholder="F.eks.”Jacob”"
          setValue={setAuthor}
          error={errors.includes('author') && 'Du skal indtaste hvem der har skrevet ideen'}
          setError={() => setErrors(errors.filter(error => error !== 'author'))}
          white={darkColors.includes(color)}
          maxLength={50}
          required={true}
        />

        <Input
          label="Hvilken by bor du/i i?"
          helperText="Skriv navnet på den by, du/I bor i."
          name="city"
          type="text"
          value={city}
          placeholder="F.eks. ”Randers”"
          setValue={setCity}
          error={errors.includes('city') && 'Du skal indtaste en by'}
          setError={() => setErrors(errors.filter(error => error !== 'city'))}
          white={darkColors.includes(color)}
          maxLength={50}
          required={true}
        />

        <div className={styles.errors}>
          {errors.includes('conflict') && <p>Du skal skrive en konflikt</p>}
          {errors.includes('author') && <p>Du skal indtaste hvem der har skrevet ideen</p>}
          {errors.includes('city') && <p>Du skal indtaste navnet på den by, du/I bor i.</p>}
        </div>
      </div>

      <div className={styles.actions}>
        <Button
          text="Tilbage"
          alternative={true}
          action={() => setStep(1)}
        />
        <Button
          submit={true}
          text="Se din idé"
          action={(e: React.MouseEvent) => {
            e.preventDefault()
            handleValidation()
          }}
        />
      </div>
    </>
  )
}
