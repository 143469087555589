import React from 'react'
import Video from '../Video/Video'
import Overlay from '../Overlay/Overlay'
import styles from './styles.module.scss'

export default function OverlayVideo({
  isOpen,
  setIsOpen,
  title,
  src,
  poster,
}: {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  title: string
  src: string
  poster: string

}) {
  return (

    <Overlay
      isOpen={isOpen}
      background="overlay-background"
      setIsOpen={setIsOpen}
      title={title}
      fitContent={true}
    >
      <div className={styles.introVideo}>
        <Video
          src={src}
          poster={poster}
          autoplay={true}
        />
      </div>
    </Overlay>
  )
}
