import React from 'react'
import Button from '../Button/Button'
import type { ProposalColor } from '../../types/proposal.type'

import ProposalCard from '../ProposalCard/ProposalCard'
import styles from './NewProposal.module.scss'

export default function Step3({
  setStep,
  title,
  icon,
  color,
  mainCharacter,
  conflict,
  solution,
  author,
  city,
}: {
  setStep: (step: number) => void
  title: string
  icon: string
  color: ProposalColor
  mainCharacter: string
  conflict: string
  solution: string
  author: string
  city: string
}) {
  return (
    <>
      <div className={styles.form}>
        <ProposalCard item={{
          id: 0,
          title,
          icon,
          main_character: mainCharacter,
          conflict,
          solution,
          author,
          city,
          votes: 0,
          color,
          preview: true,
          placement: 0,
          approved: false,
          campaign_id: 0,
          bubble_no: 0,
        }}
        />

      </div>
      <div className={styles.actions}>
        <Button
          text="Tilbage"
          alternative={true}
          action={() => setStep(2)}
        />
        <Button
          text="Fortsæt"
          action={() => setStep(4)}
        />
      </div>
    </>
  )
}
