import React, { useEffect, useRef, useState } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import Overlay from '../Overlay/Overlay'
import { getRandomColor } from '../../helpers/color'
import { useIsAuthenticated } from '../../hooks/auth'
import type { ProposalColor } from '../../types/proposal.type'
import { createProposal } from '../../actions/proposal'
import { getRandomIcon } from '../IconPicker/IconPicker'
import styles from './NewProposal.module.scss'
import Step1 from './Step1'
import Step2 from './Step2'
import Step3 from './Step3'
import Step4 from './Step4'
import Step5 from './Step5'
import Step7 from './Step7'
import Step6 from './Step6'
import Step8 from './Step8'

export default function NewProposal({
  isOpen,
  setIsOpen,
  setOpenConsent,
}: {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  setOpenConsent: (openConsent: boolean) => void
}) {
  const overlayRef = useRef<HTMLDivElement | null>(null)

  const isAuthentificated = useIsAuthenticated()
  const [step, setStep] = useState(1)
  const [stepExiting, setStepExiting] = useState(1)
  const [authError, setAuthError] = useState(false)
  const [isFetching, setIsFetching] = useState(false)

  const [color, setColor] = useState<ProposalColor>(getRandomColor('klassen-skriver') as ProposalColor)
  const [title, setTitle] = useState('')
  const [icon, setIcon] = useState(getRandomIcon())
  const [mainCharacter, setMainCharacter] = useState('')
  const [conflict, setConflict] = useState('')
  const [solution, setSolution] = useState('')
  const [author, setAuthor] = useState('')
  const [city, setCity] = useState(isAuthentificated && isAuthentificated.city !== null ? isAuthentificated.city : '')

  const [fullname, setFullname] = useState(isAuthentificated && isAuthentificated.fullname !== null ? isAuthentificated.fullname : '')
  const [grade, setGrade] = useState(isAuthentificated && isAuthentificated.grade !== null ? isAuthentificated.grade : '')
  const [school, setSchool] = useState(isAuthentificated && isAuthentificated.school !== null ? isAuthentificated.school : '')
  const [mobile, setMobile] = useState(isAuthentificated ? isAuthentificated.mobile : '')
  const [parentFullname, setParentFullname] = useState(isAuthentificated && isAuthentificated.parent_fullname !== null ? isAuthentificated.parent_fullname : '')
  const [parentPhone, setParentPhone] = useState(isAuthentificated && isAuthentificated.parent_phone !== null ? isAuthentificated.parent_phone : '')
  const [permission, setPermission] = useState(false)
  const [pin, setPin] = useState('')
  const [repeatPin, setRepeatPin] = useState('')

  const variants = {
    enter: (direction: number) => {
      return {
        x: direction > stepExiting ? 100 : -100,
        opacity: 0,
      }
    },
    center: {
      zIndex: 1,
      x: 0,
      opacity: 1,
    },
    exit: (direction: number) => {
      setStepExiting(step)

      return {
        zIndex: 0,
        x: step > direction ? 100 : -100,
        opacity: 0,
      }
    },
  }

  const stepTitle = () => {
    switch (step) {
      case 3:
        return 'Sådan ser din idé ud'

      case 4:
        return 'Kontaktoplysninger'

      case 5:
        return isAuthentificated ? 'Indtast pinkode' : 'Vælg pinkode'

      case 6:
        return 'Gentag pinkode'

      case 7:
        return 'Sender din idé...'

      case 8:
        return 'Sådan!'

      default:
        return 'Skriv din idé'
    }
  }

  const handleSubmit = async (e?: React.FormEvent) => {
    e?.preventDefault()
    setIsFetching(true)
    setStep(7)
    setAuthError(false)
    const auth = await createProposal({
      color,
      title,
      icon,
      mainCharacter,
      conflict,
      solution,
      author,
      grade,
      school,
      city,
      fullname,
      mobile,
      parentFullname,
      parentPhone,
      permission,
      pin,
    })

    if (!auth) {
      setTimeout(() => {
        setIsFetching(false)
        setAuthError(true)
        setStep(5)
        setPin('')
        setRepeatPin('')
      }, 2000)
    }
    else {
      setTimeout(() => {
        setStep(8)
        setIsFetching(false)
      }, 2000)
    }
  }

  useEffect(() => {
    if (isAuthentificated && pin.length === 4 && step === 5 && !authError)
      void handleSubmit()

    if (!isAuthentificated && repeatPin.length === 4 && step === 6 && !authError && pin === repeatPin)
      void handleSubmit()
  }, [isAuthentificated, pin, repeatPin])

  useEffect(() => {
    if (overlayRef.current)
      overlayRef.current.scrollTo({ top: 0 })
  }, [step])

  return (
    <Overlay
      isOpen={isOpen}
      background={color}
      setIsOpen={setIsOpen}
      title={stepTitle()}
      direction="bottom"
      hideClose={step === 6 || step === 7}
      overlayRef={overlayRef}
    >

      <AnimatePresence initial={false} custom={step} mode="wait">
        {step === 1 && (
          <motion.form
            className={styles.step}
            key="step1"
            custom={1}
            variants={variants}
            initial="enter"
            animate="center"
            exit="exit"
            transition={{ x: { type: 'spring', stiffness: 300, damping: 30 }, opacity: { duration: 0.2 } }}
          >
            <Step1
              setStep={setStep}
              color={color}
              setColor={setColor}
              title={title}
              setTitle={setTitle}
              icon={icon}
              setIcon={setIcon}
            />
          </motion.form>
        )}

        {step === 2 && (
          <motion.form
            className={styles.step}
            key="step2"
            custom={2}
            variants={variants}
            initial="enter"
            animate="center"
            exit="exit"
            transition={{ x: { type: 'spring', stiffness: 300, damping: 30 }, opacity: { duration: 0.2 } }}
          >
            <Step2
              setStep={setStep}
              color={color}
              mainCharacter={mainCharacter}
              setMainCharacter={setMainCharacter}
              conflict={conflict}
              setConflict={setConflict}
              solution={solution}
              setSolution={setSolution}
              author={author}
              setAuthor={setAuthor}
              city={city}
              setCity={setCity}
            />
          </motion.form>
        )}

        {step === 3 && (
          <motion.div
            className={`${styles.step} ${styles.backgroundWhite}`}
            key="step3"
            custom={3}
            variants={variants}
            initial="enter"
            animate="center"
            exit="exit"
            transition={{ x: { type: 'spring', stiffness: 300, damping: 30 }, opacity: { duration: 0.2 } }}
          >
            <Step3
              setStep={setStep}
              title={title}
              color={color}
              icon={icon}
              mainCharacter={mainCharacter}
              conflict={conflict}
              solution={solution}
              author={author}
              city={city}
            />
          </motion.div>
        )}

        {step === 4 && (
          <motion.form
            className={`${styles.step} ${styles.backgroundWhite}`}
            key="step4"
            custom={4}
            variants={variants}
            initial="enter"
            animate="center"
            exit="exit"
            transition={{ x: { type: 'spring', stiffness: 300, damping: 30 }, opacity: { duration: 0.2 } }}
          >
            <Step4
              setStep={setStep}
              fullname={fullname}
              setFullname={setFullname}
              grade={grade}
              setGrade={setGrade}
              school={school}
              setSchool={setSchool}
              mobile={mobile}
              setMobile={setMobile}
              parentFullname={parentFullname}
              setParentFullname={setParentFullname}
              parentPhone={parentPhone}
              setParentPhone={setParentPhone}
              permission={permission}
              setPermission={setPermission}
              setOpenConsent={setOpenConsent}
            />
          </motion.form>
        )}

        {step === 5 && (
          <motion.form
            className={`${styles.step} ${styles.backgroundWhite}`}
            key="step5"
            custom={5}
            variants={variants}
            initial="enter"
            animate="center"
            exit="exit"
            transition={{ x: { type: 'spring', stiffness: 300, damping: 30 }, opacity: { duration: 0.2 } }}
          >
            <Step5
              setStep={setStep}
              pin={pin}
              setPin={setPin}
              isAuthentificated={isAuthentificated}
              submit={handleSubmit}
              authError={authError}
              isFetching={isFetching}
            />
          </motion.form>
        )}

        {step === 6 && (
          <motion.form
            className={`${styles.step} ${styles.backgroundWhite}`}
            key="step6"
            custom={6}
            variants={variants}
            initial="enter"
            animate="center"
            exit="exit"
            transition={{ x: { type: 'spring', stiffness: 300, damping: 30 }, opacity: { duration: 0.2 } }}
          >
            <Step6
              setStep={setStep}
              pin={pin}
              repeatPin={repeatPin}
              setRepeatPin={setRepeatPin}
              submit={handleSubmit}
              isFetching={isFetching}
            />
          </motion.form>
        )}

        {step === 7 && (
          <motion.div
            className={`${styles.step} ${styles.backgroundWhite}`}
            key="step7"
            custom={7}
            variants={variants}
            initial="enter"
            animate="center"
            exit="exit"
            transition={{ x: { type: 'spring', stiffness: 300, damping: 30 }, opacity: { duration: 0.2 } }}
          >
            <Step7 />
          </motion.div>
        )}

        {step === 8 && (
          <motion.div
            className={`${styles.step}`}
            key="step8"
            custom={8}
            variants={variants}
            initial="enter"
            animate="center"
            exit="exit"
            transition={{ x: { type: 'spring', stiffness: 300, damping: 30 }, opacity: { duration: 0.2 } }}
          >
            <Step8 />
          </motion.div>
        )}

      </AnimatePresence>

    </Overlay>
  )
}
