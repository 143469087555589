import React from 'react'
import styles from './Hero.module.scss'

export default function Hero({
  headline,
  children,
}: {
  headline: string
  children?: React.ReactNode
}) {
  return (
    <div className={styles.heroOuter}>
      <div className={styles.hero}>
        <h1>{headline}</h1>
        {children}
      </div>
    </div>
  )
}
