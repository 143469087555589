import React from 'react'
import Logo from '../Logo/Logo'
import DrtvLink from '../DrtvLink/DrtvLink'
import styles from './Footer.module.scss'

export default function Footer() {
  const frontend = import.meta.env.VITE_FRONTEND as string || 'ultraforslag'

  if (frontend === 'klassen-skriver') {
    return (
      <footer className={styles.footer}>

        <img className={styles.aotLogo} src="/images/aot_ultra.png" alt="Alle ord tæller og DR Ultra" width={120} />
        <Logo type={frontend} />
        <img src="/images/klassen_footer_mobile.png" alt="klassen" className={styles.mobile} />
        <img src="/images/klassen_footer_desktop.png" alt="klassen" className={styles.desktop} />
      </footer>

    )
  }

  else {
    return (
      <footer className={styles.footer}>

        <DrtvLink />

        <h2>ULTRAFORSLAG</h2>
        <Logo type={frontend} />
      </footer>
    )
  }
}
