import React from 'react'
import { router } from '@inertiajs/react'
import Logo from '../../Logo/Logo'
import Icon from '../../Icon/Icon'
import HeaderNav from '../HeaderNav/HeaderNav'
import styles from './Header.module.scss'

export default function Header({
  openNav,
  setOpenNav,
  setOpenLogin,
  setOpenNewBill,
  title,
}: {
  openNav: boolean
  setOpenNav: (open: boolean) => void
  setOpenLogin: (openLogin: boolean) => void
  setOpenNewBill: (openNewBill: boolean) => void
  title?: string
}) {
  return (
    <>
      <header className={`${styles.header} ${title ? styles.hasTitle : ''}`}>
        <div className={styles.inner}>
          {title && <div className={styles.title}>{title}</div>}
          <div className={styles.logo} onClick={() => router.get('/')}>
            <Logo type="klassen-skriver" />
          </div>

          <HeaderNav setOpenLogin={setOpenLogin} setOpenNewBill={setOpenNewBill} />

          <button className={styles.burger} onClick={() => setOpenNav(!openNav)}>
            {!openNav
              ? <div><Icon name="burger" /></div>
              : <div><Icon name="close" /></div>}
          </button>
        </div>
      </header>
    </>
  )
}
