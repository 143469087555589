export interface Bill {
  id: number
  firstname: string
  city: string
  emoji: string
  title: string
  text: string
  reason: string
  votes: number
  color: BillColor
  placement: number
  approved: boolean
  preview?: boolean
  campaign_id: number
}

export type BillColor = 'turquoise' | 'yellow' | 'pink' | 'brown' | 'light-brown' | 'dark-green' | 'green' | 'dark-blue' | 'deep-blue' | 'beige' | 'red' | 'white' | 'black' | 'light-gray'
export const colors: BillColor[] = ['turquoise', 'yellow', 'pink', 'brown', 'light-brown', 'dark-green', 'green', 'dark-blue', 'deep-blue', 'beige', 'red']
export const darkColors: BillColor[] = ['dark-green', 'dark-blue', 'deep-blue', 'red']
