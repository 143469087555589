import React from 'react'
import { router } from '@inertiajs/react'
import Button from '../Button/Button'
import Video from '../Video/Video'
import styles from './NewProposal.module.scss'

export default function Step8() {
  return (
    <>
      <div className={styles.form}>

        <img src="/images/high_five.png" alt="high_five" className={styles.high_five} />

        <p className={styles.center}>
          Tak for din idé!
          <br />
          Nye idéer bliver offentliggjort løbende.
        </p>
      </div>

      <div className={styles.actions}>
        <Button
          text="ALLE idéer"
          action={() => router.get('/')}
          alternative={true}
        />
        <Button
          text="DINE idéer"
          action={() => router.get('/mine')}
        />
      </div>
    </>
  )
}
