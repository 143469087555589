import React from 'react'

import { router } from '@inertiajs/react'
import { useIsAuthenticated } from '../../hooks/auth'
import type { pageProps } from '../../types/page.type'
import Video from '../../components/Video/Video'
import Button from '../../components/Button/Button'
import type { Proposal } from '../../types/proposal.type'
import ProposalList from '../../components/ProposalList/ProposalList'
import Hero from '../../components/klassenSkriver/Hero/Hero'
import styles from './mine.module.scss'
import Layout from './Layout'

export default function Page() {
  const participant = useIsAuthenticated()

  if (!participant)
    return router.get('/')

  const approvedItems: pageProps['proposals'] = {
    data: (participant.proposals.data || []).filter(proposal => proposal.approved).map((proposal: Proposal, index: number) => ({ ...proposal, placement: index + 1 })),
    links: {
      first: null,
      last: null,
      next: null,
      prev: null,
    },
    meta: {
      current_page: 1,
      last_page: 1,
    },
  }

  const unapproveditems: pageProps['proposals'] = {
    data: (participant.proposals.data || []).filter(proposal => !proposal.approved).map((proposal: Proposal, index: number) => ({ ...proposal, preview: true, placement: index + 1 })),
    links: {
      first: null,
      last: null,
      next: null,
      prev: null,
    },
    meta: {
      current_page: 1,
      last_page: 1,
    },
  }

  return (
    <Layout headerTitle="Dine idéer">

      <Hero headline="Her er dine idéer.">
        <h2>Hej! Her kan du se de idéer, du har sendt ind til ‘Klassen skriver’. Husk at dele din idé, så den kan få mange stemmer.</h2>
      </Hero>

      {approvedItems.data.length > 0
        ? (
          <ProposalList proposals={approvedItems} shareable={true} />
          )
        : (
          <div className={styles.none}>
            <Video
              src="https://nx60199.your-storageshare.de/s/KyHew558CbxkZpq/download/VENTE.mov"
              autoplay={true}
              loop={true}
              controls={false}
            />
            <h3>{`${unapproveditems.data.length > 1 ? 'Dine idéer' : 'din idé'} er ved at blive godkendt.`}</h3>
          </div>
          )}

      {unapproveditems.data.length > 0 && (
        <>
          <div className={styles.unapproved}>
            <h3>Dine idéer, som ikke er blevet godkendt endnu.</h3>

            <ProposalList proposals={unapproveditems} />
          </div>
        </>
      )}

      <div className={styles.actions}>
        <Button
          text="se ALLE idéer"
          action={() => router.get('/')}
          alternative={true}
        />
      </div>

    </Layout>
  )
}
