import React, { useState } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import Button from '../Button/Button'
import Headline from '../Headline/Headline'
import Input from '../Form/Input/Input'
import ColorPicker from '../ColorPicker/ColorPicker'
import EmojiPicker from '../EmojiPicker/EmojiPicker'
import type { BillColor } from '../../types/bill.type'
import { colors, darkColors } from '../../types/bill.type'
import styles from './NewBill.module.scss'

export default function Step1({
  setStep,
  color,
  setColor,
  emoji,
  setEmoji,
  title,
  setTitle,
}: {
  setStep: (step: number) => void
  color: BillColor
  setColor: (color: BillColor) => void
  emoji: string
  setEmoji: (emoji: string) => void
  title: string
  setTitle: (title: string) => void
}) {
  const [sortedColors, _setSortedColors] = useState(() => [color, ...colors.filter(c => c !== color)])

  return (
    <>
      <div className={styles.top}>
        <ColorPicker colors={sortedColors} color={color} setColor={setColor} />
        <EmojiPicker emoji={emoji} setEmoji={setEmoji} />
      </div>

      <div className={styles.form}>
        <AnimatePresence>
          {title && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <Headline text={title} />
            </motion.div>
          )}
        </AnimatePresence>

        <Input
          label="Titel"
          name="title"
          value={title}
          placeholder="SKRIV EN TITEL"
          setValue={setTitle}
          helperText={`${title.length} ud af 50 tegn`}
          error={title.length > 50 && 'Titlen må maks være 50 tegn'}
          white={darkColors.includes(color)}
        />

        <AnimatePresence>
          {title === '' && (
            <motion.div
              className={styles.helperText}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              Giv dit Ultraforslag en overskrift -
              {' '}
              <br />
              skriv kort hvad forslaget går ud på.
            </motion.div>
          )}
        </AnimatePresence>

      </div>
      <div className={styles.actions}>
        <Button
          submit={true}
          text="fortsæt"
          disabled={title.length > 50 || title === ''}
          action={(e: React.MouseEvent) => {
            e.preventDefault()
            setStep(2)
          }}
        />
      </div>
    </>
  )
}
