import React from 'react'

import PageTitle from '../../components/PageTitle/PageTitle'
import type { pageProps } from '../../types/page.type'
import TabNavigation from '../../components/TabNavigation/TabNavigation'
import Hero from '../../components/klassenSkriver/Hero/Hero'
import ProposalList from '../../components/ProposalList/ProposalList'
import Layout from './Layout'

export default function Page({ proposals }: pageProps) {
  return (
    <Layout>
      <TabNavigation active={2} />

      <Hero headline="Populære idéer">
        <p>Her kan du se de idéer, der lige nu har fået flest stemmer.</p>
      </Hero>

      {/* <PageTitle title="Populære idéer" /> */}

      <ProposalList proposals={proposals} infoElementPlacement={6} />
    </Layout>
  )
}
