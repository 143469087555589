import React from 'react'
import OtpInput from 'react-otp-input'
import Button from '../Button/Button'
import styles from './NewProposal.module.scss'

export default function Step6({
  setStep,
  pin,
  repeatPin,
  setRepeatPin,
  submit,
  isFetching,
}: {
  setStep: (step: number) => void
  pin: string
  repeatPin: string
  setRepeatPin: (pin: string) => void
  submit: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void | Promise<void>
  isFetching: boolean
}) {
  if (isFetching) {
    return (
      <div>
        Vent venligst mens vi indsender din idé...
      </div>
    )
  }

  return (
    <>
      <div className={styles.form}>
        <p className={styles.center}>Gentag pinkoden</p>

        <div className={styles.pin}>
          <OtpInput
            value={repeatPin}
            onChange={setRepeatPin}
            numInputs={4}
            inputType="tel"
            shouldAutoFocus={true}
            renderInput={props => <input {...props} />}
          />
        </div>

        {repeatPin.length === 4 && pin !== repeatPin && (
          <div className={styles.text}>
            <p className={styles.center}>PINKODERNE skal være ens!</p>
          </div>
        )}
      </div>

      <div className={styles.actions}>
        <Button
          text="Tilbage"
          alternative={true}
          action={() => setStep(4)}
        />
        <Button
          submit={true}
          text={`${isFetching ? 'Færdiggøre din idé..' : 'Færdig'}`}
          disabled={isFetching || repeatPin.length !== 4 || pin.length !== 4 || pin !== repeatPin}
          action={submit}
        />
      </div>
    </>
  )
}
