import React, { useState } from 'react'
import { router, usePage } from '@inertiajs/react'
import { motion } from 'framer-motion'
import BillCard from '../Bill/Bill'
import type { Bill } from '../../types/bill.type'
import type { pageProps } from '../../types/page.type'
import Video from '../Video/Video'
import DrtvLink from '../DrtvLink/DrtvLink'
import styles from './BillsList.module.scss'

export default function BillsList({
  bills: pageBills,
  withPlace,
  shareable,
  infoElementPlacement,
  random,
}: {
  bills: pageProps['bills']
  withPlace?: boolean
  shareable?: boolean
  infoElementPlacement?: number
  random?: boolean
}) {
  const pageComponent = usePage().component
  const url = pageComponent === 'trendingBills' ? '/trending-bills' : '/'

  if (random)
    pageBills.data.sort(() => Math.random() - 0.5)

  const [bills, setBills] = useState(pageBills.data)
  const [billsLeft, setBillsLeft] = useState(pageBills.data.filter(bill => bill.placement % 2 !== 0))
  const [billsRight, setBillsRight] = useState(pageBills.data.filter(bill => bill.placement % 2 === 0))
  const [isFetching, setIsFetching] = useState(false)

  const handleLoadmore = () => {
    if (!pageBills.links.next)
      return

    if (isFetching)
      return

    setIsFetching(true)

    router.get(pageBills.links.next, {}, {
      preserveState: true,
      preserveScroll: true,
      onSuccess: (page: any) => {
        window.history.replaceState({}, '', url)
        // eslint-disable-next-line ts/no-unsafe-assignment, ts/no-unsafe-member-access
        const newBills: Bill[] = page.props.bills.data

        if (random)
          newBills.sort(() => Math.random() - 0.5)

        const newBillsLeft: Bill[] = []
        const newBillsRight: Bill[] = []

        newBills.forEach((bill: Bill) => {
          if (bill.placement % 2 !== 0)
            newBillsLeft.push(bill)
          else
            newBillsRight.push(bill)
        })

        setBillsLeft([...billsLeft, ...newBillsLeft])
        setBillsRight([...billsRight, ...newBillsRight])
        setBills([...bills, ...newBills])

        setTimeout(() => {
          setIsFetching(false)
        }, 200)
      },
    })
  }

  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting)
        handleLoadmore()
    })
  }, { root: null, rootMargin: '0px 0px 150px 0px', threshold: 0.9 })

  return (
    <>
      <div className={`${styles.bills} ${styles.twoColumns}`}>
        <div>
          {billsLeft.map((bill: Bill) => {
            return (
              <div key={bill.placement}>
                <motion.div
                  initial={{ y: '100%', opacity: 0 }}
                  animate={{ y: '0%', opacity: 1 }}
                  transition={{ duration: 0.5 }}
                >
                  <BillCard bill={bill} withPlace={withPlace} shareable={shareable} />
                </motion.div>
                {infoElementPlacement && ((bill.placement + 1) === infoElementPlacement + 4 || (bill.placement > 10 && (bill.placement - 5) % 20 === 0)) && (
                  <motion.div
                    initial={{ y: '100%', opacity: 0 }}
                    animate={{ y: '0%', opacity: 1 }}
                    transition={{ duration: 0.5 }}
                    className={styles.infoElement}
                  >
                    <DrtvLink title={true} />
                  </motion.div>
                )}
              </div>
            )
          })}
        </div>

        <div>
          {billsRight.map((bill: Bill) => {
            return (
              <div key={bill.placement}>
                <motion.div
                  initial={{ y: '100%', opacity: 0 }}
                  animate={{ y: '0%', opacity: 1 }}
                  transition={{ duration: 0.5 }}
                >
                  <BillCard bill={bill} withPlace={withPlace} shareable={shareable} />
                </motion.div>

                {infoElementPlacement && (bill.placement === infoElementPlacement || bill.placement % 20 === 0) && (
                  <motion.div
                    initial={{ y: '100%', opacity: 0 }}
                    animate={{ y: '0%', opacity: 1 }}
                    transition={{ duration: 0.5 }}
                    className={styles.infoElement}
                  >
                    <Video
                      src="https://nx60199.your-storageshare.de/s/TfZNxFzYWkcQTJL/download/intro.mp4"
                      poster="/images/video-thumps/intro.jpg"
                      title="Har du selv et godt forslag?"
                    />
                  </motion.div>
                )}
              </div>
            )
          })}
        </div>
      </div>

      <div className={`${styles.bills} ${styles.singleColumn}`}>
        {bills.map((bill: Bill) => {
          return (
            <div key={bill.placement}>
              <motion.div
                initial={{ y: '100%', opacity: 0 }}
                animate={{ y: '0%', opacity: 1 }}
                transition={{ duration: 0.5 }}
              >
                <BillCard bill={bill} withPlace={withPlace} hasVoteHandling={true} shareable={shareable} />
              </motion.div>

              {infoElementPlacement && bill.placement > 1 && bill.placement % 20 === 0 && (
                <motion.div
                  initial={{ y: '100%', opacity: 0 }}
                  animate={{ y: '0%', opacity: 1 }}
                  transition={{ duration: 0.5 }}
                  className={styles.infoElement}
                >
                  <DrtvLink title={true} />
                </motion.div>
              )}

              {infoElementPlacement && (bill.placement === infoElementPlacement || ((infoElementPlacement < 10 && bill.placement !== 10) && bill.placement % 10 === 0 && bill.placement % 20 !== 0)) && (
                <motion.div
                  initial={{ y: '100%', opacity: 0 }}
                  animate={{ y: '0%', opacity: 1 }}
                  transition={{ duration: 0.5 }}
                  className={styles.infoElement}
                >
                  <Video
                    src="https://nx60199.your-storageshare.de/s/TfZNxFzYWkcQTJL/download/intro.mp4"
                    poster="/images/video-thumps/intro.jpg"
                    title="Har du selv et godt forslag?"
                  />
                </motion.div>
              )}
            </div>
          )
        })}
      </div>

      {!isFetching && <div className={styles.observer} ref={ref => ref && observer.observe(ref)}>---</div>}
    </>
  )
}
