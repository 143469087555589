import React from 'react'

import PageTitle from '../../components/PageTitle/PageTitle'
import type { pageProps } from '../../types/page.type'
import TabNavigation from '../../components/TabNavigation/TabNavigation'
import ProposalList from '../../components/ProposalList/ProposalList'
import Hero from '../../components/klassenSkriver/Hero/Hero'
import OverlayVideo from '../../components/videos/OverlayVideo'
import Layout from './Layout'
import styles from './newest.module.scss'

export default function Page({ proposals }: pageProps) {
  const [showVideo, setShowVideo] = React.useState(false)

  return (
    <Layout>
      <TabNavigation active={3} />

      <Hero headline="Hvad er din idé til ’Klassen’?">
        <p>Med ’Klassen skriver’ har du mulighed for selv at være med til at bestemme, hvad DR Ultras populære serie ’Klassen’ skal handle om.</p>
        <p>
          Send DIN idé ind til et afsnit.
          <br />
          Du kan også stemme på andres idéer.
        </p>

        <img
          src="/images/video-thumps/saadan_goer_du-klassen_skriver.png"
          alt="Har du selv et godt forslag?"
          onClick={() => setShowVideo(true)}
          className={styles.video}
        />

        {showVideo && (
          <OverlayVideo
            isOpen={showVideo}
            setIsOpen={setShowVideo}
            title="Sådan fungerer konkurrencen"
            poster="/images/video-thumps/saadan_goer_du-klassen_skriver-poster.jpg"
            src="https://nx60199.your-storageshare.de/s/6aG7Aw7HEcccW6D/download/SAADAN_GOER_DU.mov"
          />
        )}

        <img className={styles.aotLogo} src="/images/aot_ultra_dr.png" alt="Alle ord tæller og DR Ultra" />

      </Hero>

      <PageTitle title="Tidligere ideér" />

      <ProposalList proposals={proposals} infoElementPlacement={10} />

    </Layout>

  )
}
