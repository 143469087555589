import React from 'react'
import { createInertiaApp } from '@inertiajs/react'
import { createRoot } from 'react-dom/client'

// import * as Sentry from '@sentry/react'

// Sentry.init({
//   dsn: 'https://3c3e4235bd3ec2ff9ab0a9b33c7e71b7@o1002703.ingest.us.sentry.io/4507210147102720',
//   integrations: [
//     Sentry.browserTracingIntegration(),
//     Sentry.replayIntegration(),
//   ],
//   // Performance Monitoring
//   tracesSampleRate: 0.1, //  Capture 100% of the transactions
//   // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//   tracePropagationTargets: [/^https:\/\/ultraforslag\.dk/],
//   // Session Replay
//   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// })

void createInertiaApp({
  resolve: (name) => {
    const pages = import.meta.glob('./pages/**/*.tsx', { eager: true })
    const frontend = import.meta.env.VITE_FRONTEND as string || 'ultraforslag'

    if (frontend === 'klassen-skriver')
      import('../styles/klassen-skriver.scss')

    else
      import('../styles/ultraforslag.scss')

    return pages[`./pages/${frontend}/${name}.tsx`]
  },
  setup({ el, App, props }) {
    createRoot(el).render(<App {...props} />)
  },
})
