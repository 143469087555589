import React, { useState } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import Button from '../Button/Button'
import Headline from '../Headline/Headline'
import Input from '../Form/Input/Input'
import ColorPicker from '../ColorPicker/ColorPicker'
import type { ProposalColor } from '../../types/proposal.type'
import { colors, darkColors } from '../../types/proposal.type'
import IconPicker from '../IconPicker/IconPicker'
import styles from './NewProposal.module.scss'

export default function Step1({
  setStep,
  color,
  setColor,
  title,
  setTitle,
  icon,
  setIcon,
}: {
  setStep: (step: number) => void
  color: ProposalColor
  setColor: (color: ProposalColor) => void
  title: string
  setTitle: (title: string) => void
  icon: string
  setIcon: (icon: string) => void
}) {
  const [sortedColors, _setSortedColors] = useState(() => [color, ...colors.filter(c => c !== color)])
  const [openIconPicker, setOpenIconPicker] = useState(false)

  return (
    <>
      <IconPicker isOpen={openIconPicker} setIsOpen={setOpenIconPicker} color={color} setIcon={setIcon} />

      <div className={styles.top}>
        <ColorPicker colors={sortedColors} color={color} setColor={setColor} />
        <div className={styles.iconButton}>
          <img
            src={`/images/icons/${icon}.png`}
            alt="Ikon"
            onClick={() => setOpenIconPicker(true)}
          />
        </div>
      </div>

      <div className={styles.form}>
        <AnimatePresence>

          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className={styles.topPreview}
          >
            {/* {icon && <img src={`/images/icons/${icon}.png`} alt="Ikon" className={styles.icon} />} */}
            <Headline text={title || 'Hvad handler afsnittet om?'} backgroundNo={0} />
          </motion.div>

        </AnimatePresence>

        <Input
          label="Hvad handler afsnittet om?"
          helperText="Giv din idé en titel."
          name="title"
          value={title}
          placeholder="F.eks. ”Pranken der går galt”"
          setValue={setTitle}
          error={title.length > 50 && 'Titlen må maks være 50 tegn'}
          white={darkColors.includes(color)}
          maxLength={50}
          showCounter={true}
        />

      </div>
      <div className={styles.actions}>
        <Button
          submit={true}
          text="fortsæt"
          disabled={title.length > 50 || title === ''}
          action={(e: React.MouseEvent) => {
            e.preventDefault()
            setStep(2)
          }}
        />
      </div>
    </>
  )
}
