import React from 'react'
import { router } from '@inertiajs/react'
import Layout from '../Layout'
import Hero from '../../../components/klassenSkriver/Hero/Hero'
import OverlayVideo from '../../../components/videos/OverlayVideo'
import Video from '../../../components/Video/Video'
import styles from './page.module.scss'

export default function Page() {
  const openConsent = () => {
    router.get('/about?open-consent', {}, {
      preserveScroll: true,
    })
  }

  return (
    <Layout>
      <Hero headline="Om Klassen skriver" />

      <div className={styles.content}>
        <h3>Hvad er ’Klassen skriver’?</h3>
        <p>’Klassen skriver’ er børn og unges mulighed for at være med til at bestemme, hvad DR Ultras populære serie ’Klassen’ skal handle om. Hvis du er under 15 år, så kan du her sende DIN idé ind til et afsnit.</p>
        <p>Hvis du vinder, bliver din idé til et rigtigt afsnit af ’Klassen’ – og du og din klasse får besøg af en ægte ægte manuskriptforfatter og skuespiller fra ’Klassen’, hvor I sammen skriver jeres helt eget afsnit af ’Klassen’ til den nye sæson.</p>
        <p>Så hvad venter du på? Indsend din idé NU! </p>

        <h3>Hvad skal min idé indeholde?</h3>
        <p>Når du sender din idé ind, skal du skrive, hvad afsnittet skal handle om, hvem der er med i afsnittet, hvad hovedrollen synes er svært, og hvordan hovedrollen kommer ud af sit problem.</p>

        <p>Derudover skal din idé overholde nogle få betingelser: </p>
        <ul className={styles.list}>
          <li>Din idé skal kunne foregå på en skole – altså ikke på en rumstation eller i Sydspanien.</li>
          <li>Din idé skal kunne foregå over et par dage – altså ikke flere måneder eller år.</li>
          <li>Din idé skal kunne spilles af ’Klassen’-skuespillere – altså ikke af Tobias Rahim eller statsministeren.</li>
          <li>Din idé må IKKE indeholde hadbeskeder, oplysninger om identificerbare personer, mobning, indhold, der i øvrigt er i strid med lovgivningen eller lignende. Hvis idéen gør det, bliver den ikke offentliggjort og kan altså ikke findes på hjemmesiden eller få stemmer.</li>
        </ul>

        <Video
          poster="/images/video-thumps/saadan_goer_du-klassen_skriver-poster.jpg"
          src="https://nx60199.your-storageshare.de/s/6aG7Aw7HEcccW6D/download/SAADAN_GOER_DU.mov"
        />

        <h3>Hvem kan deltage og hvordan?</h3>
        <p>Du skal være under 15 år for at deltage i konkurrencen eller stemme på andres idéer.</p>
        <p>For at indsende en idé og stemme på andres idéer. skal du være logget ind på hjemmesiden. Du logger ind med dit telefonnummer og laver din egen 4-cifrede pinkode. Husk din pinkode til næste gang du logger ind.</p>
        <p>Du kan godt indsende flere idéer, men du skal sende én idé ad gangen. Du kan stemme på alle idéer én gang. Du kan ikke stemme flere gange på den samme idé.</p>

        <p>På alle hverdage offentliggøres der nye idéer.</p>
        <p>Der skal være en person på minimum 18 år, der hjælper dig med at indsende din idé.</p>

        <p>Blandt de 50 idéer, der har fået flest stemmer, finder DR 10 vindere, der sammen med deres klasse får besøg af en skuespiller og en manuskriptforfatter fra ’Klassen’ – og som sammen med dig og din klasse skriver et afsnit til en ny sæson af ’Klassen’.</p>

        <img src="/images/klassen_skriver_theme_thump.jpg" alt="Inspiration til at få ’Klassen’ ind i klassen med DR Skole" />

        <h3>Inspiration til at få ’Klassen’ ind i klassen med DR Skole</h3>
        <p>
          DR Skole har lavet et gratis inspirationskatalog til mellemtrinnet, som kan bruges til at arbejde med ’Klassen skriver’ og som leder frem mod, at eleverne kan indsende deres egne idéer til et afsnit af ’Klassen’. I kataloget finder du blandt andet sjove øvelser og instruktionsvideoer med ægte ’Klassen’-skuespillere. Inspirationskataloget har til formål at give eleverne viden om og erfaring med kreativ idéudvikling og manuskriptskrivning. Find materialet
          {' '}
          <a href="https://www.dr.dk/skole/dansk/mellemtrin/tema/klassen-skriver" target="_blank">her</a>
          .
        </p>

        <h3>Alle ord tæller</h3>
        <p>’Klassen skriver’ er en del af DR’s projekt ’Alle ord tæller’, som har til formål at skabe bedre forudsætninger for gode læseoplevelser og derigennem styrke læselysten og læsekulturen for danske børn i målgruppen 1-14 år. Projektet er støttet af TrygFonden.</p>

        <h3>Vilkårsbetingelser</h3>
        <p>
          <a onClick={openConsent}>Læs her vilkårsbetingelserne for deltagelse i ’Klassen skriver’.</a>
        </p>
        <p>
          Har du spørgsmål eller brug for hjælp, kan du skrive til
          {' '}
          <a href="mailto:alleordtaeller@dr.dk" target="_blank">alleordtaeller@dr.dk</a>
          .
        </p>

        <p>Se videoen om ’Klassen skriver’ her</p>
        <Video
          poster="/images/video-thumps/youtubere_bestemmer-poster.jpg"
          src="https://nx60199.your-storageshare.de/s/sgdEEeFymWcGWHx/download/KAMPAGNESITE_KLASSEN_1000_YOUTUBERE_BESTEMMER.mp4"
        />

      </div>

    </Layout>
  )
}
