import React from 'react'
import Overlay from '../Overlay/Overlay'
import styles from './IconPicker.module.scss'

const icons = [
  'blyant',
  'bog_1',
  'bog_2',
  'flyver',
  'hashtag',
  'heart_1',
  'heart_2',
  'heart_3',
  'krussedulle_1',
  'krussedulle_2',
  'krussedulle_3',
  'krussedulle_4',
  'krussedulle_5',
  'list',
  'net',
  'node_1',
  'node_2',
  'node_3',
  'node_4',
  'node_5',
  'parantes',
  'prikker',
  'questionmark',
  'stars',
  'waves',
  'yell_2',
  'yell',
  'zigzag_1',
  'zigzag_2',
]

export default function IconPicker({
  isOpen,
  setIsOpen,
  color,
  setIcon,
}: {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  color: string
  setIcon: (icon: string) => void
}) {
  return (
    <Overlay isOpen={isOpen} background={color} setIsOpen={setIsOpen} title="Vælg et ikon">
      <div className={styles.container}>
        {icons.map((icon, index) => (
          <img
            key={index}
            className={styles.icon}
            src={`/images/icons/${icon}.png`}
            onClick={() => {
              setIcon(icon)
              setIsOpen(false)
            }}
          />
        ))}
      </div>
    </Overlay>
  )
}

export function getRandomIcon() {
  const randomIndex = Math.floor(Math.random() * icons.length)

  return icons[randomIndex]
}
