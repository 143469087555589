export interface Proposal {
  id: number
  author: string
  city: string
  icon: string
  title: string
  main_character?: string
  conflict: string
  solution?: string
  votes: number
  color: ProposalColor
  placement: number
  approved: boolean
  preview?: boolean
  campaign_id: number
  bubble_no: number
}

export type ProposalColor = 'white' | 'black' | 'orange' | 'olive' | 'green' | 'purple' | 'lime' | 'yellow' | 'blue-gray' | 'blue'
export const colors: ProposalColor[] = ['orange', 'olive', 'green', 'purple', 'lime', 'yellow', 'blue-gray', 'blue']
export const darkColors: ProposalColor[] = ['black']
