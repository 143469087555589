import React from 'react'
import styles from './Headline.module.scss'

export default function Headline({ text, backgroundNo }: { text: string, backgroundNo?: number }) {
  const frontend = import.meta.env.VITE_FRONTEND as string || 'ultraforslag'

  const backgrounds = [
    'bubble_1',
    'bubble_2',
    'bubble_3',
    'bubble_4',
  ]
  const background = typeof backgroundNo !== 'undefined' ? styles[backgrounds[backgroundNo]] : ''

  return (
    <h2
      className={`${frontend === 'ultraforslag' ? styles.ultraforslag : styles.klasssenSkriver} ${background}`}
    >
      <span>{text}</span>
    </h2>
  )
}
