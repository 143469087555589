import React, { useState } from 'react'
import { router, usePage } from '@inertiajs/react'
import { motion } from 'framer-motion'
import type { pageProps } from '../../types/page.type'
import type { Proposal } from '../../types/proposal.type'
import ProposalCard from '../ProposalCard/ProposalCard'
import OverlayVideo from '../videos/OverlayVideo'
import styles from './ProposalList.module.scss'

export default function ProposalList({
  proposals: pageProposals,
  withPlace,
  shareable,
  infoElementPlacement,
  random,
}: {
  proposals: pageProps['proposals']
  withPlace?: boolean
  shareable?: boolean
  infoElementPlacement?: number
  random?: boolean
}) {
  const pageComponent = usePage().component
  const url = pageComponent === 'trendingBills' ? '/trending-bills' : '/'

  if (!pageProposals)
    return null

  if (random)
    pageProposals.data.sort(() => Math.random() - 0.5)

  const [items, setitems] = useState(pageProposals.data)
  const [leftItems, setLeftItems] = useState(pageProposals.data.filter(proposal => proposal.placement % 2 !== 0))
  const [rightItems, setRightItems] = useState(pageProposals.data.filter(proposal => proposal.placement % 2 === 0))
  const [isFetching, setIsFetching] = useState(false)

  const [showVideo, setShowVideo] = useState([false, false, false, false, false])

  const handleLoadmore = () => {
    if (!pageProposals.links.next)
      return

    if (isFetching)
      return

    setIsFetching(true)

    router.get(pageProposals.links.next, {}, {
      preserveState: true,
      preserveScroll: true,
      onSuccess: (page: any) => {
        window.history.replaceState({}, '', url)
        // eslint-disable-next-line ts/no-unsafe-assignment, ts/no-unsafe-member-access
        const newItems: Proposal[] = page.props.proposals.data

        if (random)
          newItems.sort(() => Math.random() - 0.5)

        const newleftItems: Proposal[] = []
        const newrightItems: Proposal[] = []

        newItems.forEach((proposal: Proposal) => {
          if (proposal.placement % 2 !== 0)
            newleftItems.push(proposal)
          else
            newrightItems.push(proposal)
        })

        setLeftItems([...leftItems, ...newleftItems])
        setRightItems([...rightItems, ...newrightItems])
        setitems([...items, ...newItems])

        setTimeout(() => {
          setIsFetching(false)
        }, 200)
      },
    })
  }

  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting)
        handleLoadmore()
    })
  }, { root: null, rootMargin: '0px 0px 150px 0px', threshold: 0.9 })

  const feedVideos = [
    {
      img: '/images/video-thumps/har_du_en_ide.png',
      alt: 'Har du selv en god idé?',
      onClick: () => setShowVideo([true, false, false, false, false]),
      title: 'Har du selv en god idé?',
      poster: '/images/video-thumps/har_du_en_ide-poster.jpg',
      src: 'https://nx60199.your-storageshare.de/s/dopBafBN3PotTcJ/download/HAR_DU_EN_IDE.mov',
    },
    {
      img: '/images/video-thumps/youtubere_bestemmer.png',
      alt: 'Youtube bestemmer',
      onClick: () => setShowVideo([false, true, false, false, false]),
      title: 'Youtube bestemmer',
      poster: '/images/video-thumps/youtubere_bestemmer-poster.jpg',
      src: 'https://nx60199.your-storageshare.de/s/sgdEEeFymWcGWHx/download/KAMPAGNESITE_KLASSEN_1000_YOUTUBERE_BESTEMMER.mp4',
    },
    {
      img: '/images/video-thumps/jasmin_og_mika.png',
      alt: 'Jasmin og Mika',
      onClick: () => setShowVideo([false, false, true, false, false]),
      title: 'Jasmin og Mika',
      poster: '/images/video-thumps/jasmin_og_mika-poster.jpg',
      src: 'https://nx60199.your-storageshare.de/s/G5x3dqQwfdmmfRd/download/KAMPAGNESITE_KLASSEN_1000_JASMIN_OG_MIKA.mp4',
    },
    {
      img: '/images/video-thumps/morten_munster.png',
      alt: 'Morten Munster',
      onClick: () => setShowVideo([false, false, false, true, false]),
      title: 'Morten Munster',
      poster: '/images/video-thumps/morten_munster-poster.jpg',
      src: 'https://nx60199.your-storageshare.de/s/bApn5XdZgp8QedJ/download/KAMPAGNESITE_KLASSEN_1000_MORTEN.mp4',
    },
    {
      img: '/images/video-thumps/mrspyplant.png',
      alt: 'mrspyplant',
      onClick: () => setShowVideo([false, false, false, false, true]),
      title: 'mrspyplant',
      poster: '/images/video-thumps/mrspyplant-poster.jpg',
      src: 'https://nx60199.your-storageshare.de/s/RP5QjMyWR5tqyko/download/KAMPAGNESITE_KLASSEN_1000_MRSPYPLANT_V2.mp4',
    },
  ]

  const InfoElementPlacement = (index: number) => {
    if (!infoElementPlacement)
      return null

    return (
      <motion.div
        initial={{ y: '100%', opacity: 0 }}
        animate={{ y: '0%', opacity: 1 }}
        transition={{ duration: 0.5 }}
        className={styles.infoElement}
      >
        <img
          src={feedVideos[index].img}
          alt={feedVideos[index].alt}
          onClick={() => feedVideos[index].onClick()}
        />
      </motion.div>
    )
  }

  return (
    <>
      {feedVideos.map((video, index) =>
        showVideo[index] && (
          <OverlayVideo
            key={index}
            isOpen={showVideo[index]}
            setIsOpen={isOpen => setShowVideo(showVideo.map((v, i) => i === index ? isOpen : v))}
            title={video.title}
            poster={video.poster}
            src={video.src}
          />
        ),
      )}

      <div className={`${styles.items} ${styles.twoColumns}`}>
        <div>
          {leftItems.map((item: Proposal) => {
            const renderInfoElement = () => {
              switch ((item.placement + 1) % 50) {
                case 10:
                  return InfoElementPlacement(0)
                case 20:
                  return InfoElementPlacement(1)
                case 30:
                  return InfoElementPlacement(2)
                case 40:
                  return InfoElementPlacement(3)
                case 0: // 50, 100, 150, etc.
                  return InfoElementPlacement(4)
              }
            }

            return (
              <div key={item.placement}>
                <motion.div
                  initial={{ y: '100%', opacity: 0 }}
                  animate={{ y: '0%', opacity: 1 }}
                  transition={{ duration: 0.5 }}
                >
                  <ProposalCard item={item} withPlace={withPlace} shareable={shareable} />
                </motion.div>
                {renderInfoElement()}
              </div>
            )
          })}
        </div>

        <div>
          {rightItems.map((item: Proposal) => {
            const renderInfoElement = () => {
              switch (item.placement % 50) {
                case 10:
                  return InfoElementPlacement(2)
                case 20:
                  return InfoElementPlacement(3)
                case 30:
                  return InfoElementPlacement(4)
                case 40:
                  return InfoElementPlacement(0)
                case 0: // 50, 100, 150, etc.
                  return InfoElementPlacement(1)
              }
            }

            return (
              <div key={item.placement}>
                <motion.div
                  initial={{ y: '100%', opacity: 0 }}
                  animate={{ y: '0%', opacity: 1 }}
                  transition={{ duration: 0.5 }}
                >
                  <ProposalCard item={item} withPlace={withPlace} shareable={shareable} />
                </motion.div>

                {renderInfoElement()}
              </div>
            )
          })}
        </div>
      </div>

      <div className={`${styles.items} ${styles.singleColumn}`}>
        {items.map((item: Proposal) => {
          const renderInfoElement = () => {
            switch (item.placement % 50) {
              case 10:
                return InfoElementPlacement(0)
              case 20:
                return InfoElementPlacement(1)
              case 30:
                return InfoElementPlacement(2)
              case 40:
                return InfoElementPlacement(3)
              case 0: // 50, 100, 150, etc.
                return InfoElementPlacement(4)
            }
          }

          return (
            <div key={item.placement}>
              <motion.div
                initial={{ y: '100%', opacity: 0 }}
                animate={{ y: '0%', opacity: 1 }}
                transition={{ duration: 0.5 }}
              >
                <ProposalCard item={item} withPlace={withPlace} hasVoteHandling={true} shareable={shareable} />
              </motion.div>

              {renderInfoElement()}
            </div>
          )
        })}
      </div>

      {!isFetching && <div className={styles.observer} ref={ref => ref && observer.observe(ref)}>---</div>}
    </>
  )
}
