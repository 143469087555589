export async function vote(id: number, model = 'bill'): Promise<boolean> {
  try {
    const response = await fetch('/api/vote', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
      body: JSON.stringify({
        id,
        model,
      }),
    })

    return response.status === 200
  }
  catch (error) {
    throw new Error('Failed to vote')
  }
}
