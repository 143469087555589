import React from 'react'
import styles from './Logo.module.scss'

export default function Logo({ type }: { type: string }) {
  if (type === 'klassen-skriver') {
    return (
      <div className={styles.klassen}>
        <img src="/images/klassenskriver_logo.png" alt="Klassen skriver" />
      </div>
    )
  }

  else if (type === 'ultraforslag') { return <img className={styles.logo} src="/images/logo.svg" alt="Logo" /> }
}
