import React from 'react'
import { usePage } from '@inertiajs/react'
import Overlay from '../Overlay/Overlay'
import styles from './Paused.module.scss'

export default function Paused({
  isOpen,
  setIsOpen,
}: {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
}) {
  const pausedMessage = usePage().props.pausedMessage

  return (
    <Overlay
      isOpen={isOpen}
      background="overlay-background"
      setIsOpen={setIsOpen}
      title=""
      fitContent={true}
    >
      <div className={styles.container}>
        <h2 className={styles.header} dangerouslySetInnerHTML={{ __html: pausedMessage as string }} />
      </div>
    </Overlay>
  )
}
