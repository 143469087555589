import React from 'react'
import { motion } from 'framer-motion'
import { router, usePage } from '@inertiajs/react'
import { logout } from '../../../actions/auth'
import { useIsAuthenticated } from '../../../hooks/auth'
import styles from './NavMobile.module.scss'

export default function NavMobile({
  isOpen,
  setIsOpen,
  setOpenLogin,
  setOpenNewBill,
}: {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  setOpenLogin: (openLogin: boolean) => void
  setOpenNewBill: (openNewBill: boolean) => void
}) {
  const isAuthentificated = useIsAuthenticated()
  const url = usePage().props.url as string
  const campaign = usePage().props.campaign as boolean

  return (
    <motion.nav
      className={styles.nav}
      initial={{ x: '100%' }}
      animate={{ x: isOpen ? '0' : '100%' }}
      transition={{ type: 'spring', stiffness: 200, damping: 20 }}
    >
      <a onClick={() => router.get('/')}>Se alle idéer</a>
      {campaign && (
        <a onClick={() => {
          setIsOpen(false)
          setOpenNewBill(true)
        }}
        >
          Skriv din idé
        </a>
      )}

      {/* {isAuthentificated && isAuthentificated.bills.data.length > 0 && <a onClick={() => router.get('/my-bills')}>Dine forslag</a>} */}

      <a onClick={() => router.get('/about')}>Om klassen skriver</a>

      {campaign && (isAuthentificated
        ? (
          <>

            <a onClick={() => {
              setIsOpen(false)
              logout(url)
            }}
            >
              Log ud
            </a>
          </>
          )
        : (
          <a onClick={() => {
            setIsOpen(false)
            setOpenLogin(true)
          }}
          >
            Log ind
          </a>
          ))}
    </motion.nav>
  )
}
