import React from 'react'
import { router } from '@inertiajs/react'
import Layout from '../Layout'
import Button from '../../../components/Button/Button'
import ProposalCard from '../../../components/ProposalCard/ProposalCard'
import type { Proposal } from '../../../types/proposal.type'
import styles from './page.module.scss'

export default function Page({ proposal }: { proposal: { data: Proposal } }) {
  return (
    <Layout>

      <div className={styles.item}>
        <ProposalCard item={proposal.data} withPlace={false} />
        <div className={styles.actions}>
          <Button
            text="Se andre idéer"
            action={() => router.get('/')}
            alternative={true}
          />
        </div>
      </div>

    </Layout>
  )
}
