import React, { useEffect, useState } from 'react'
import Button from '../Button/Button'
import Input from '../Form/Input/Input'
import Checkbox from '../Form/Checkbox/Checkbox'
import styles from './NewProposal.module.scss'

export default function Step4({
  setStep,
  fullname,
  setFullname,
  grade,
  setGrade,
  school,
  setSchool,
  mobile,
  setMobile,
  parentFullname,
  setParentFullname,
  parentPhone,
  setParentPhone,
  permission,
  setPermission,
  setOpenConsent,
}: {
  setStep: (step: number) => void
  fullname: string
  setFullname: (fullname: string) => void
  grade: string
  setGrade: (grade: string) => void
  school: string
  setSchool: (school: string) => void
  mobile: string
  setMobile: (mobile: string) => void
  parentFullname: string
  setParentFullname: (parentFullname: string) => void
  parentPhone: string
  setParentPhone: (parentPhone: string) => void
  permission: boolean
  setPermission: (permission: boolean) => void
  setOpenConsent: (openConsent: boolean) => void
}) {
  const [errors, setErrors] = useState<string[]>([])

  const handleValidation = () => {
    let newErrors: string[] = []
    setErrors(newErrors)

    const fields: { [key: string]: string | boolean } = {
      fullname,
      grade,
      school,
      mobile,
      parentFullname,
      parentPhone,
      permission,
    }

    const validationRules: { [key: string]: (value: string | boolean) => boolean } = {
      fullname: value => typeof value === 'string' && (value.length === 0 || value === parentFullname),
      grade: value => typeof value === 'string' && (value.length === 0),
      school: value => typeof value === 'string' && (value.length === 0),
      mobile: value => typeof value === 'string' && (value.length !== 8),
      parentFullname: value => typeof value === 'string' && (value.length === 0 || value === fullname),
      parentPhone: value => typeof value === 'string' && (value.length !== 8),
      permission: value => !value,
    }

    Object.keys(validationRules).forEach((field) => {
      const isInvalid = validationRules[field as keyof typeof validationRules](fields[field as keyof typeof fields])
      if (isInvalid && !newErrors.includes(field))
        newErrors.push(field)
      else if (!isInvalid)
        newErrors = newErrors.filter(error => error !== field)
    })
    setErrors(newErrors)
    if (newErrors.length === 0)
      setStep(5)
  }

  const permissionLabel = (
    <>
      {`Jeg bekræfter, at jeg er over 18 år og har læst, forstået og accepteret
      `}
      <a onClick={() => {
        setTimeout(() => {
          setPermission(permission)
        }, 200)
        setOpenConsent(true)
      }}
      >
        Betingelserne for ‘Klassen skriver’,
      </a>
      {` samt DR's generelle `}
      <a
        href="https://www.dr.dk/om-dr/dr-og-dine-data/drs-privatlivspolitik"
        target="_blank"
        onClick={() => {
          setTimeout(() => {
            setPermission(permission)
          }, 200)
        }}
      >
        Privatlivspolitik.
      </a>
      {` Jeg indestår for, at forældremyndighedsindehaverne til det barn, der indsender idéen til ‘Klassen skriver’, har givet tilladelse til indsendelsen i henhold til betingelserne. Ved delt/fælles forældremyndighed skal begge forældremyndighedsindehavere samtykke.`}
    </>
  )

  return (
    <>
      <div className={styles.form}>
        <p>Hvis din idé er blandt dem med flest stemmer og vinder konkurrencen, skal vi bruge dine kontaktoplysninger. Oplysningerne behandles i overensstemmelse med den gældende lovgivning, herunder databeskyttelsesforordningen (GDPR), og de vil ikke blive vist på siden.</p>

        <Input
          label="Dit fulde navn"
          name="fullname"
          type="text"
          value={fullname}
          placeholder="F.eks. ”Sofie Hansen.”"
          setValue={setFullname}
          error={errors.includes('fullname') && 'Du skal indtaste dit fulde navn'}
          setError={() => setErrors(errors.filter(error => error !== 'fullname'))}
          required={true}
        />

        <Input
          label="Dit telefonnummer"
          name="mobile"
          type="tel"
          value={mobile}
          placeholder="F.eks. ”11223344”"
          maxLength={8}
          setValue={setMobile}
          error={errors.includes('mobile') && 'Du skal indtaste dit mobilnummer'}
          setError={() => setErrors(errors.filter(error => error !== 'mobile'))}
          required={true}
        />

        <Input
          label="Hvilken klasse går du i?"
          name="grade"
          type="text"
          value={grade}
          placeholder="F.eks. ”6.B”"
          setValue={setGrade}
          error={errors.includes('grade') && 'Du skal indtaste en din klasse'}
          setError={() => setErrors(errors.filter(error => error !== 'grade'))}
          required={true}
        />

        <Input
          label="Hvilken skole går du på?"
          name="school"
          type="text"
          value={school}
          placeholder="F.eks. ”Strandvejsskolen”"
          setValue={setSchool}
          error={errors.includes('school') && 'Du skal indtaste din skole'}
          setError={() => setErrors(errors.filter(error => error !== 'school'))}
          required={true}
        />

        <Input
          label="Din forælders eller lærers fulde navn"
          name="parentFullname"
          type="text"
          value={parentFullname}
          placeholder="F.eks. ”Lars Hansen”"
          setValue={setParentFullname}
          error={errors.includes('parentFullname') && 'Du skal indtaste din forælders eller lærers fulde navn'}
          setError={() => setErrors(errors.filter(error => error !== 'parentFullname'))}
          required={true}
        />

        <Input
          label="Din forælders eller lærers telefonnummer"
          name="parentPhone"
          type="tel"
          value={parentPhone}
          maxLength={8}
          placeholder="F.eks. ”11223344”"
          setValue={setParentPhone}
          error={errors.includes('parentPhone') && 'Du skal indtaste din forælders eller lærers telefonnummer'}
          setError={() => setErrors(errors.filter(error => error !== 'parentPhone'))}
          required={true}
        />

        <Checkbox
          label={permissionLabel}
          name="permission"
          value={permission}
          setValue={setPermission}
          error={errors.includes('permission') && 'Du skal have lov af en voksen'}
          setError={() => setErrors(errors.filter(error => error !== 'permission'))}
        />

        <div className={styles.errors}>
          {errors.includes('fullname') && <p>Du skal indtaste dit fulde navn</p>}
          {errors.includes('mobile') && <p>Du skal indtaste dit mobilnummer</p>}
          {errors.includes('grade') && <p>Du skal indtaste hvilken klasse du går i</p>}
          {errors.includes('school') && <p>Du skal indtaste din skole</p>}
          {errors.includes('parentFullname') && <p>Du skal indtaste din forælders eller lærers fulde navn</p>}
          {errors.includes('parentPhone') && <p>Du skal indtaste din forælders eller lærers telefonnummer</p>}
        </div>

      </div>
      <div className={styles.actions}>
        <Button
          text="Tilbage"
          alternative={true}
          action={() => setStep(3)}
        />
        <Button
          submit={true}
          text="Indsend"
          action={(e: React.MouseEvent) => {
            e.preventDefault()
            handleValidation()
          }}
        />
      </div>
    </>
  )
}
