import React from 'react'
import styles from './Input.module.scss'

export default function Input({
  label,
  name,
  placeholder,
  type = 'text',
  value,
  setValue,
  error,
  setError,
  helperText,
  white,
  whiteBackground,
  maxLength,
  maxNumber,
  minNunber,
  showCounter,
  required,
}: {
  label?: string
  placeholder?: string
  type?: 'text' | 'text-area' | 'number' | 'tel'
  name: string
  value: string
  setValue: (value: string) => void
  error?: string | false
  setError?: (value: boolean) => void
  helperText?: string
  white?: boolean
  whiteBackground?: boolean
  maxLength?: number
  maxNumber?: number
  minNunber?: number
  showCounter?: boolean
  required?: boolean
}) {
  return (
    <div className={`${styles.inputWrapper} ${white ? styles.white : ''} ${whiteBackground ? styles.whiteBackground : ''}`}>

      {label && (
        <label htmlFor={name} className={`${styles.label} ${error ? styles.error : ''}`}>
          {required && <span className={styles.required}>* </span>}
          {label}
        </label>
      )}

      {helperText && <div className={styles.helperText}>{helperText}</div>}

      {(type === 'text' || type === 'number' || type === 'tel') && (
        <input
          id={name}
          className={`${styles.input} ${error ? styles.error : ''}`}
          type={type}
          name={name}
          value={value}
          placeholder={placeholder}
          onChange={(e) => {
            setValue(e.target.value)
            setError && setError(false)
          }}
          autoComplete="off"
          maxLength={maxLength}
          max={maxNumber}
          min={minNunber}
        />
      )}

      {type === 'text-area' && (
        <textarea
          id={name}
          className={`${styles.input} ${error ? styles.error : ''}`}
          name={name}
          value={value}
          placeholder={placeholder}
          onChange={(e) => {
            setValue(e.target.value)
            setError && setError(false)
          }}
          autoComplete="off"
          onInput={(e: React.FormEvent<HTMLTextAreaElement>) => {
            const target = e.target as HTMLTextAreaElement
            target.style.height = 'auto'
            target.style.height = `${target.scrollHeight}px`
          }}
          maxLength={maxLength}
        />
      )}
      {showCounter && maxLength && <div className={styles.counter}>{`${value.length} ud af ${maxLength} tegn`}</div>}

    </div>
  )
}
