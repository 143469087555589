import React from 'react'
import { motion } from 'framer-motion'
import Icon from '../Icon/Icon'
import styles from './Overlay.module.scss'

export default function Overlay({
  isOpen,
  setIsOpen,
  background,
  title,
  children,
  direction,
  hideClose,
  fitContent,
  overlayRef,
}: {
  isOpen: boolean
  setIsOpen: (open: boolean) => void
  background: string
  title: string
  children: React.ReactNode
  direction?: 'bottom' | 'right'
  hideClose?: boolean
  fitContent?: boolean
  overlayRef?: React.RefObject<HTMLDivElement>
}) {
  const frontend = import.meta.env.VITE_FRONTEND as string || 'ultraforslag'

  return (
    isOpen && (
      <>
        <div
          ref={overlayRef}
          className={`${styles.overlay} ${frontend === 'klassen-skriver' ? styles.hardShadow : ''} ${styles[background]} ${direction} ${fitContent ? styles.fitContent : ''}`}
        >
          <div className={styles.header}>
            <div className={styles.title}>{title}</div>
            {!hideClose && (
              <button
                className={styles.close}
                onClick={() => setIsOpen(false)}
              >
                <motion.div whileHover={{ scale: 1.3 }}><Icon name="close" /></motion.div>
              </button>
            )}
          </div>
          <div className={styles.content}>
            {children}
          </div>
        </div>
        <div className={styles.backdrop} onClick={() => setIsOpen(false)}></div>
      </>
    )
  )
}
