import React from 'react'
import OtpInput from 'react-otp-input'
import Button from '../Button/Button'
import type { Participant } from '../../types/participant.type'
import styles from './NewBill.module.scss'

export default function Step5({
  setStep,
  pin,
  setPin,
  submitBill,
  authError,
  isFetching,
  isAuthentificated,
}: {
  setStep: (step: number) => void
  pin: string
  setPin: (pin: string) => void
  submitBill: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void | Promise<void>
  authError: boolean
  isFetching: boolean
  isAuthentificated: Participant | null
}) {
  if (isFetching) {
    return (
      <div>
        Vent venligst mens vi indsender dit forslag...
      </div>
    )
  }

  return (
    <>
      <div className={styles.form}>
        {isAuthentificated
          ? <p className={styles.center}>Indtast den pinkode du valgte da du oprettede din profil</p>
          : <p className={styles.center}>For at kunne se dine forslag igen, skal du oprette en 4-cifret pinkode</p>}

        <div className={`${styles.pin} ${authError ? styles.authError : ''}`}>
          <OtpInput
            value={pin}
            onChange={setPin}
            numInputs={4}
            inputType="tel"
            shouldAutoFocus={true}
            renderInput={props => <input {...props} />}
          />
        </div>

        {authError && (
          <div className={styles.text}>
            <p className={styles.center}>Du er allerede logget ind.</p>
            <p className={styles.center}>Indtast den PIN-kode du valgte da du oprettede din profil</p>
          </div>
        )}
      </div>

      <div className={styles.actions}>
        <Button
          text="Tilbage"
          alternative={true}
          action={() => setStep(4)}
        />

        {isAuthentificated
          ? (
            <Button
              submit={true}
              text={`${isFetching ? 'Færdiggøre dit forslag..' : 'Færdig'}`}
              disabled={isFetching || pin.length !== 4}
              action={submitBill}
            />
            )
          : (
            <Button
              text="fortsæt"
              disabled={pin.length !== 4}
              action={() => setStep(6)}
            />
            )}

      </div>
    </>
  )
}
